footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding-top: 24px;
}
footer h6 {
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin-bottom: 8px;
}
footer img {
    width: 200px;
}
footer p {
    color: rgba(255, 255, 255, .6);
    font-size: 12px;
    margin: 0;
    margin-bottom: 12px;
    text-align: center;
}