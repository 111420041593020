.text {
    color: #fff;
    padding: 0 12px;
}
.text ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.text ul img {
    height: 23px;
    width: 23px;
}
.text li {
    display: flex;
    gap: 4px;
}
.text ul p {
    margin: 0;
}