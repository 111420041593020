@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141414;
}
* .yellow {
  color: #E0F300!important;
}
* .bold {
  font-weight: bold!important;
}
* .mt {
  margin-top: 48px;
}
* .mt-sm {
  margin-top: 24px;
}
* .pb {
  padding-bottom: 42px;
}
* p {
  font-weight: 300;
}