.oferta {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 24px;
    font-family: 'Inter', sans-serif;
    color: #fff;
    background: #E0561E;
    box-shadow: 4px 4px 22px rgba(224, 86, 30, .75);
    margin-bottom: 24px;
}
.oferta h3 {
    font-size: 32px;
    margin-bottom: 16px;
}
.oferta .head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.oferta .img {
    /* display: block; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ffff;
    border-bottom: 1px solid #ffff;
}
.oferta img {
    height: 220px;
    padding-top: 18px;
}
.oferta p.desc {
    background: rgba(20, 20, 20,.6);
    width: 250px;
    border-radius: 8px;
    padding: 8px;
}
.oferta h5 {
    font-weight: bold;
    font-size: 32px;
    margin: 0;
    margin-top: 24px;

}
.oferta h5 span {
    font-weight: lighter;
    margin: 0;
    font-size: 16px;
}
.oferta h6 {
    font-size: 48px;
    margin-top: 16px;
    margin-bottom: 0;
}
.oferta h6 span {
    font-size: 16px;
    font-weight: lighter;
}
.oferta .footer {
    padding: 18px 0;
}
.oferta .footer p {
    margin-top: 24px;
    width: 260px;
}
.oferta .economize {
    display: inline-block;
    padding: 12px 32px;
    font-weight: bold;
    color: #141414;
    background: #fff000;
    border-radius: 4px;
    margin: 0;
}
.oferta a {
    text-decoration: none;
}