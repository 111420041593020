.headline {
    margin-top: 12px;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.headline p,
.headline h2 {
    margin: 0;
}