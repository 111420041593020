.card {
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    background: linear-gradient(90deg, #ff0000 0%, #141414 100%);
    margin-bottom: 12px;
}
.card div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.card h4 {
    font-size: 20px;
    margin: 0;
}
.card p {
    font-weight: 300;
    padding: 0 16px;
}